import {Pages}         from "./Pages";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";
import ReactDOM        from "react-dom";
import React        from "react";
import {PersonForm} from "../components/PersonForm";
import {HelpButton} from "../dom/HelpButton";
import {app}           from "../../index";

export class PersonsEdit extends Pages {

    initPageHandlers(params) {
        super.initPageHandlers(params);

        let $form = $("#person_edit_form"),
            $birthDate = $("#birthdate"),
            dateFormat = app.config.DEFAULT_DATEPICKER_DATE_FORMAT;

        this.data = window.diaryJsData;

        ReactDOM.render(
            <HelpButton
                dataLoadUrl = {"person.edit.help"}/>,
            document.getElementById('personEditHelpButton')
        );

        ReactDOM.render(
            <PersonForm
                backUrl = {this.data.backUrl}
                person={this.data.person}/>,
            document.getElementById('personEditForm')
        );

        $birthDate
            .datepicker({
                dateFormat: dateFormat,
                defaultDate: "-11Y",
                changeMonth: true
            });

        $form.validate(
            {
                rules: {
                    "element[f]": {
                        required: true,
                    },
                    "element[i]": {
                        required: true,
                    },
                    "element[birthdate]": {
                        required: true
                    },
                    "element[email]": {
                        email: true,
                        require_from_group: [1, '.contacts_group']
                    },
                    "element[phone]": {
                        require_from_group: [1, '.contacts_group']
                    },
                },
                messages: {
                    "element[email]": {
                        require_from_group: "Одно из полей (телефон или email) должно быть заполнено!",
                    },
                    "element[phone]": {
                        require_from_group: "Одно из полей (телефон или email) должно быть заполнено!",
                    },
                }
            }
        );


    }

}
